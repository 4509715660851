import * as Types from './types';

export const initialState = {
  coach: null,
  coachProfile: {},
  error: null,
  coacheeList: [],
  loading: false,
  coachee: {},
  currentJourney: undefined,
  journeys: []
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.USER_GET: {
      // TEMP: support shape of response from mono
      if (action.payload.user) {
        return {
          ...state,
          ...action.payload.user,
          coach: action.payload.coach,
          selectedJourney: action.payload.user.currentJourney,
          loading: false
        };
      }

      return {
        ...state,
        ...action.payload,
        selectedJourney: action.payload.currentJourney,
        loading: false
      };
    }
    case Types.COACHEES_GET: {
      return {
        ...state,
        coacheeList: action.payload,
        loading: false
      };
    }
    case Types.COACHEE_GET: {
      return {
        ...state,
        coachee: action.payload,
        loading: false
      };
    }
    case Types.COACH_GET: {
      return {
        ...state,
        coach: action.payload.coachProfile,
        coachProfile: action.payload.coachProfile,
        loading: false
      };
    }
    case Types.PREVIOUS_COACH_GET: {
      return {
        ...state,
        previousCoach: action.payload.coachProfile,
        loading: false
      };
    }
    case Types.USER_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.USER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case Types.USER_UPDATED: {
      return {
        ...state,
        ...action.payload
      };
    }
    case Types.SET_SELECTED_JOURNEY: {
      return {
        ...state,
        selectedJourney: action.payload
      };
    }
    default:
      return state;
  }
};
